import React from "react"
import { Link, navigate } from "gatsby"
import Seo from "../components/seo"
import { login, isLoggedIn } from '../services/auth.js'

class Login extends React.Component {
  state = {
    Password: "",
    MemberNo: "",
    LoginMessage: ""
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  handleLogin = async event => {
    event.preventDefault()
    if (await login(this.state)) {
      this.setState({
        Password: "",
        MemberNo: "",
        IsFailed: false
      });
    } else {
      this.setState({
        IsFailed: true
      });
    }
  }


  render = () => {
    if (isLoggedIn()) {
      // ログイン済みの場合は会員ページへ
      navigate("/userpage");
      return null;
    }

    const errorField = this.state.IsFailed ? <div className="err aC">会員番号またはパスワードが正しくありません。</div> : ""

    return (
      <>
        <Seo title="会員ログイン" />
        <div id="pageTitle">
          <h1>
            会員ログイン
          </h1>
        </div>
        <div className="breadClumb">
          <ul>
            <li><Link to="/">HOME</Link></li>
            <li>会員ログイン</li>
          </ul>
        </div>

        <div id="main">
          <div className="contents">
            <section className="contents_block">
              <div className="inner_login">
                <p className="mb30">このページは会員専用ページです。<br />
                  会員でない方はご入会をお勧めいたします。<Link to="/member" className="loginMessage">入会のご案内はこちらをご覧ください</Link></p>
                <form className="login_form" onSubmit={this.handleLogin}>
                  <div className="login_inner"><label htmlFor="member_no">会員番号</label><input id="member_no" name="MemberNo" value={this.state.MemberNo} onChange={this.handleInputChange} type="text" className="login_input" /></div>
                  <div className="login_inner"><label htmlFor="password">パスワード</label><input id="password" name="Password" value={this.state.Password} onChange={this.handleInputChange} type="password" className="login_input" /></div>
                  {errorField}
                  <div className="login_btn"><input type="submit" value="ログイン" /></div>
                </form>
                <p className="login_notice">
                  <p className="login_notice">
                    会員番号がわからない方は<Link to="/remindNumber" className="loginMessage ">こちら</Link>からお問い合わせいただくか、03-5846-5300又は054-3489へ連絡をしてください。<br />
                    パスワードのわからない方は協会誌の当月号をご覧ください。
                  </p>
                </p>
              </div>
            </section>
          </div>
        </div>
      </>
    )
  }

}

export default Login;
